.index_header{
	width:100%;
	height:110px;
	box-shadow:0px 0px 8px 0px rgba(0,0,0,0.15);
	background-color:#fff;
	padding:10px 20px;
}
.index_headimg{
	width:70px;
	height:70px;
	background-image: url('/assets/admin.png');
	background-size:100% 100%;
	border-radius:40px;
	float:left;
	margin-right:20px;
	margin-top:10px;
}
.index_title{
	float:left;
	width:30%;
	line-height:35px;
	margin-top:13px;
}
.index_title_welcome{
	font-size:20px;
	font-weight:bold;
	color:#000;
}
.index_mission{
	width:50%;
	float:right;
	margin-top:20px;
}
.index_mission_item{
	width:20%;
	float:left;
	text-align:center;
	border-right:solid 1px #E9E9E9;
}
.index_mission_title{
	color:#0061A6;
}
.index_mission_count{
	color:#000;
	font-size:18px;
}
.index_content{
	background-color:#F0F2F5;
	padding:20px;
}
.index_nav{
	width:100%;
	background-color:#fff;
	margin-bottom:20px;
	border-radius:10px;
}
.index_nav_title{
	width:100%;
	height:55px;
	line-height:55px;
	font-weight:bold;
	border-bottom:solid 1px #E9E9E9;
	padding:0 20px;
	color:#000;
}
.index_nav_process{
	padding: 0 30px;
	height:95px;
	width:100%;
	text-align:center;
}
.index_nav_table{
	padding:20px;
}
.index_nav_num{
	padding-top: 20px;
	line-height: 28px;
}
.index_nav_num_title{
	font-weight: bold;
}
.index_nav_num_content{
	width: 20%;
	float: left;
	text-align: center;
}
.index_process_item, .index_process_num, .index_process_text, .index_process_line{
	float:left;
}
.index_process_num{
	text-align:center;
	color:#fff;
	width:30px;
	height:30px;
	line-height:30px;
	border-radius:15px;
	background-color:#008FFF;
	margin-top:32px;
	margin-right:10px;
}
.index_process_text{
	line-height:95px;
	margin-right:10px;
}
.index_process_line{
	width:36px;
	height:2px;
	margin-top:48px;
	border:1px solid rgba(0,143,255,1);
	margin-right:10px;
}
.index_new_words{
	width:100%;
	float:left;
}
.index_new_item {
	background-color:#fff;
	border-radius:10px;
	margin-bottom: 20px;
	width:49%;
}
.index_new_mission{
	width:20%;
	float:right;
}
.index_new_task{
	width:100%;
	height:150px;
	background-color:#008FFF;
	color:#fff;
	margin-bottom:20px;
	border-radius:10px;
}
.index_new_case{
	width:100%;
	height:150px;
	color:#fff;
	background-color:#FFA137;
	border-radius:10px;
}
.index_new_title{
	height:50px;
	line-height:50px;
	padding-left:20px;
	border-bottom:solid 1px rgba(255,255,255,0.3);	
}
.index_new_text{
	line-height:100px;
	text-align:center;
}
.index_nav_new{
	float:right;
	font-size:12px;
}
.index_nav_new span{
	margin-right:20px;
}
.index_words{
	width:100%;
	padding:5px 20px;
}
.index_words_item{
	height:65px;
	width:100%;
	border-bottom:solid 1px #E8E8E8;
}
.index_words_content{
	float:left;
	margin:10px 0 0 5px;
	line-height:25px;
}
.index_words_btn{
	float:right;
	margin:13px 5px 0 0;
}
.blue{
	color:#1890FF;
}
.index_words_time{
	color:rgba(0,0,0,0.45);
	font-size:12px;
}

.index_count{
	overflow:hidden;
}
.index_count_item{
	height:120px;
	background-color:#fff;
	border-radius:10px;
	margin-bottom:20px;
}
.index_count_icon{
	width:120px;
	height:100%;
	border-radius:10px 0 0 10px;
	text-align:center;
	float:left;
}
.index_count_icon img{
	width:60px;
	margin-top:30px;
}
.index_count_text{
	margin-top:35px;
	margin-left:12%;
	text-align:center;
	float:left;
	line-height:26px;
}
.color-1{
	background-color:#4a8efa;
}
.color-2{
	background-color:#6fce6d;
}
.color-3{
	background-color:#f5d22a;
}
.color-4{
	background-color:#e89feb;
}
.color-5{
	background-color:#b6d6fe;
}
.color-6{
	background-color:#7be1e1;
}
.color-7{
	background-color:#e8841e;
}
.color-8{
	background-color:#e35a7a;
}
.index_container{
	padding:20px;
}
.index_content_item{
	border-right: solid 1px #D9D9D9;
	text-align: center;
	line-height: 36px;
}
.index_content_item_title{
	font-size: 16px;
	font-weight: bold;
}
.index_content_num{
	font-size: 26px;
	font-weight: bold;
}
.index_container_item{
	width:20%;
	float: left;
}
.index_table th{
  background:#fff !important;
  border-bottom: none !important;
  padding:10px 0 !important;
}
.index_table th span{
  color:#4A4A4A !important;
}
.index_table td{
  border-bottom: none !important;
  padding:10px 0 !important;
}