/*
* @Author: Joe
* @Date:   2018-11-22 16:50:22
* @Last Modified by:   Joe
* @Last Modified time: 2018-12-04 13:16:28
*/
.data-bg{
	padding:30px 20px 30px 10px;
	color:#fff;
	background: url('/assets/data-bg.png') no-repeat center center;
  background-size:cover;
  background-attachment: fixed;
  width: 100%;
  height: 100%;
  position: fixed;
  overflow-y: auto;
  top:0;
  left:0;
  right:0;
  bottom:0;
}
.data-title-content{
	overflow:hidden;
	text-align: center;
}
.data-title{	
	font-size: 28px;
}
.data-title-bg{
	padding:5px 15px;
	background-color: #011A29;
	color:#00DEFF;
	text-align: center;
	float: left;
	margin:0 5px;
	cursor: pointer;
	font-size: 14px;
	margin-top: 2px;
}
.data-back{
	float: right;
}
.data-title-border{
	height: 35px;
	float: left;
}
.data-content{
	margin-top:35px;
	overflow: hidden;
}
.data-count-title{
	width: 130px;
	height: 40px;
	line-height: 40px;
	background-size: 100% 100%;
	text-align: center;
	background-image: url('/assets/border1.png');
}
.data-count-num{
	width: 260px;
	height: 95px;
	margin-left: -15px;
	color:#FFC825;
	font-size: 26px;
	text-align: center;
	background-size: 100% 100%;
	background-image: url('/assets/border2.png');
}
.data-jiedao, .data-liuge{
	margin:20px 0 0 8px;
}
.data-map{
	position: absolute;
	top: 90px;
	left:32%;
}
.data-map-bg{
	position:relative;
	width:520px;
	height:540px;
	background-image: url('/assets/data-map-bg2.png');
	background-size:100% 100%; 
}
.data-map-content1{
	position:absolute;
	top:70px;
	left:90px;
}
.data-map-content2{
	position:absolute;
	top:200px;
	left:53px;
}
.data-map-content3{
	position:absolute;
	top:200px;
	left:130px;
}
.data-map-content4{
	position:absolute;
	top:150px;
	left:210px;
}
.data-map-content5{
	position:absolute;
	top:320px;
	left:160px;
}
.data-map-content6{
	position:absolute;
	top:310px;
	left:280px;
}
.data-map-content7{
	position:absolute;
	top:300px;
	left:350px;
}
.data-map-content8{
	position:absolute;
	top:390px;
	left:350px;
}
.data-map-content9{
	position:absolute;
	top:345px;
	left:415px;
}
.data-map-num{
	width:40px;
	height:20px;
	line-height:20px;
	text-align:center;
	border-radius:5px;
	background-color:#FFC80B;
	margin-top:5px;
	color:#000;
	font-size: 12px;
	margin-left: 10px;
}
.data-zailiyong{
	float: left;
	width: 33%;
	margin-left: 30px;
	margin-top: 35px;
}
.data-liuge{
	width: 90%;
	float: left;
	height: 270px;
	overflow: hidden;
}
.data-liuge-count, .data-zailiyong-count{
	width:100%;
	border:solid 1px #0375A4;
	clear: both;
	margin:20px 0 0 8px;
	padding:10px;
}
.data-liuge-count img{
	width:20px;
	margin-right:10px;
}
.data-liuge-count span{
	font-size: 13px;
}
.data-qiantou-count{
	border:solid 1px #0375A4;
	clear: both;
	margin:20px 0 0 0;
	padding:0 20px 10px 20px;
	height: 420px;
	margin-bottom: 6px;
	overflow-y: auto;
	font-size: 14px;
}
.data-tudi{
	margin-top: 30px;
}
.data-tudi-count{
	width:100%;
	overflow: hidden;
}
.data-qiantou-index{
	float: left;
	width:30px;
	height:15px;
	line-height: 15px;
	background-color:#00BBFF;
	color:#000;
	text-align: center;
	border-radius:3px;
	margin-top: 2px;
}
.data-qiantou-content{
	width: 100%;
	padding:10px 0;
	overflow: hidden;
}
.data-qiantou-content span{
	display: inline-block;
	float: left;
}
.data-qiantou-pie{
	width:30px;
	height: 30px;
	float:left;
	margin-left:10px;
}
.data-yellow{
	color:#FFC80B;
}
.data-green{
	color:#00FF72;
}
.data-blue{
	color:#00FFF6;
}
.data-white{
	color:#fff;
}
.data-yellow-bg{
	background-color:#FFC80B;
}
.data-green-bg{
	background-color:#00FF72;
}







