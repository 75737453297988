.grids_container {
  background-color: #f6f8ff;
  position: absolute;
  width: 100%;
}
.grids_height {
  height: calc(100% - 13rem);
}
.grids_selsect {
  // will-change: transition, opacity;
  overflow: hidden;
  margin-bottom: 1rem;
  transition: height 0.3s ease-out, margin-bottom 0.3s ease-out, opacity 0.3s ease-out;
  background-color: #fff;
  box-shadow: 0px 2px 8opacitypx 0px rgba(0, 0, 0, 0.08);
}

.grids_selsect > .ant-row {
  padding: 1.2rem 0px;
}
.grids_selsect .ant-form-item-label {
  text-align: left;
}
.grids_map {
  width: 100%;
  height: calc(100%);
  position: relative;
}
.grids_modeBtn {
  width: 45%;
  height: 70px;
  text-align: center;
  line-height: 70px;
  color: #3189fa;
  float: left;
  background-color: #fff;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}
.grids_modeBtn_active,
.grids_modeBtn:hover {
  background-color: #3189fa;
  color: #fff;
}
.grids_modeSelect {
  position: absolute;
  width: 100%;
  padding: 1rem;
  z-index: 306;
  overflow-x: auto;
  overflow-y: hidden;
}
.grids_count {
  width: 95%;
  height: 70px;
  padding: 10px 15px;
  background-color: #fff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}
.grids_count_line {
  width: 35px;
  height: 5px;
  margin-top: 10px;
  border-radius: 5px;
  float: left;
}
.grids_count_num {
  float: right;
  margin-top: -15px;
}
.grids_count_rate {
  float: left;
  color: #538bf7;
  font-size: 22px;
  margin-top: -12px;
  margin-left: 5px;
}
.grids_count_img {
  float: right;
  width: 40px;
  margin-top: -25px;
}
.grids_list {
  padding: 20px;
  background-color: #fff;
  overflow: hidden;
}
.grids_list .scroll .ant-table td {
  white-space: nowrap;
}
.geo_detail {
  width: 65px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  background: rgba(24, 144, 255, 1);
  border-radius: 4px;
  color: #fff;
  border: none;
  float: left;
  margin-right: 10px;
  cursor: pointer;
}
.geo_close {
  width: 50px;
  height: 28px;
  line-height: 26px;
  text-align: center;
  border-radius: 4px;
  float: left;
  cursor: pointer;
  color: #f5222d;
  background-color: #f5f5f5;
  border: solid 1px #d9d9d9;
}
.map_btn_group {
  width: 100%;
  height: 48px;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  bottom: 0;
  right: 0;
  box-shadow: 0 2px 6px 0 rgba(114, 124, 245, 0.5);
  text-align: center;
  padding-top: 5px;
}
.map_btn_img {
  width: 11px;
  margin-right: 5px;
}
.map_btn_group_hide {
  display: none;
}
.grids_search {
  width: 100%;
  height: 74px;
  text-align: center;
  line-height: 74px;
  background-color: #fff;
}
