.ant-form-item-label label {
  color: #0061a6 !important;
  font-weight: bold;
}
.ucase_map {
  width: 100%;
  background-color: #ccc;
}
.ucase_process {
  font-size: 16px;
  color: #909399;
}
.ucase_process_icon {
  float: right;
  line-height: 56px !important;
  font-size: 30px;
  color: #c0c4cc;
  margin-top: 14px;
}
.ucase_process_ever {
  color: #008fff;
}
.ucase_process_everIcon {
  font-size: 24px;
  margin-right: 10px;
  color: #008fff;
}
.ucase_process_now {
  color: #303133;
}
.item span {
  margin-left: 80px;
}
.detail_img {
  width: 100%;
  padding: 20px;
  border: solid 1px #ccc;
  border-radius: 5px;
  overflow: hidden;
}
.ucase_location {
  width: 100%;
  border: solid 1px #ccc;
  border-radius: 5px;
  height: 200px;
}
.ucase_process_num {
  width: 30px;
  height: 30px;
  border: solid 2px #909399;
  border-radius: 20px;
  text-align: center;
  position: absolute;
  margin: 13px 0 0 25px;
  line-height: 28px;
  font-size: 18px;
}
.black_num {
  border: solid 2px #303133;
  color: #303133;
}
.blue_num {
  border: solid 2px #008fff;
  color: #008fff;
}

.words_report {
  margin: 20px 0;
}
.words_report label,
.words_record label {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  display: block;
}
.words_report_content {
  width: 80%;
  float: left;
}
.words_report_btn {
  float: right;
  margin-top: 80px;
  margin-right: 20px;
}
.words_record_item {
  padding: 10px 20px;
  border: solid 1px #e8e8e8;
  overflow: hidden;
}
.words_record_text {
  line-height: 30px;
  width: 80%;
  float: left;
}
.words_record_btn {
  float: right;
}

.amap_markers_pop_window {
  border: 0px !important;
  padding: 0px !important;
}
.amap_markers_pop_window::after {
  display: none !important;
}

.amap-ext-info .box {
  position: relative;
  width: 290px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.6584);
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
  color: #fff;
  padding: 10px;
  font-size: 12px;
  line-height: 25px;
}
.amap-ext-info .arrow {
  position: absolute;
  width: 40px;
  height: 40px;
  bottom: -40px;
  left: 130px;
}
.amap-ext-info .arrow * {
  display: block;
  border-width: 20px;
  position: absolute;
  border-style: solid dashed dashed dashed;
  font-size: 0;
  line-height: 0;
}
.amap-ext-info .arrow em {
  border-color: #000 transparent transparent;
  opacity: 0.6;
}
.amap-ext-info .arrow span {
  border-color: #000 transparent transparent;
  opacity: 0;
}
.task_table_location {
  width: 100%;
  height: 40px;
  padding: 0 10px;
  line-height: 40px;
  background-color: #fff;
  border: solid 1px #ccc;
}
.mytable .ant-table-empty .ant-table-body {
  overflow: hidden !important;
}
.show-btn {
  overflow: hidden;
  margin-top: 10px;
}
.grids_show_btn {
  position: absolute !important;
  top: 40px;
  left: 400px;
  z-index: 100;
}
.delay-base {
  width: 15px;
  height: 15px;
  border-radius: 10px;
}
.delay {
  @extend .delay-base;
  background-color: red;
}
.not-delay {
  @extend .delay-base;
  background-color: green;
}
