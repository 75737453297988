// 任务详情
.uugm_title_content{
	width:100%;
	height:30px;
}
.uugm_title_content .w-btn a {
  color: #fff;
}
.uugm_title{
  font-size:20px;
  font-weight:bold;
  float:left;
}
.task_table{
  margin-top:20px;
  overflow:hidden;
}
.task_table_section{
  width:100%;
  line-height:54px;
  overflow:hidden;
}
.task_table_item{
  width:50%;
  height:100%;
  float:left;
}
.task_table_title{
  width:30%;
  text-align:center;
  color:#0061A6;
  float:left;
  font-weight:bold;
  height:100%;
}
.task_table_std{
  float:left;
  width:85%;
  padding:9px 20px;
}
.task_table_std .task_table_text{
  padding:0;
  width:100%
}
.task_table_text{
  padding:9px 20px;
  float:left;
  font-weight:bold;
  height:100%;
  line-height:35px;
  width:70%;
}
.task_table_map{
  width:85%;
  height:300px;
  float:left;
}
.task_table_img_container{
  width:85%;
  float:left;
}
.task_title{
  font-size: 18px;
  font-weight: bold;
  margin:15px 0 10px 0;
}
.task_item{
  margin-bottom: 15px;
  margin-left: 20px;
  line-height: 35px;
}
.task_item span{
  color:#797979;
  margin-right: 30px;
  min-width: 50px;
}
.uugm_back{
	float:right;
}
.show_btn{
	padding:0 20px;
	height:32px;
	line-height:30px;
	background:rgba(0,143,255,1);
	border-radius:4px;
	border:1px solid rgba(0,143,255,1);
	text-align:center;
	color:#fff;
	cursor:pointer;
  float:left;
  margin-right:10px;
}
.show_btn:hover{
  background-color:#40a9ff;
  border:1px solid #40a9ff;
}
.back_btn{
  border:solid 1px #CCCCCC;
  background:rgba(255,255,255,1);
  color:#666666;
}
.back_btn:hover{
  background-color:#fff;
  border:1px solid #40a9ff;
  color:#40a9ff;
}
.task_table_img_title{
  width:100%;
  height:60px;
  line-height:60px;
  background:rgba(245,246,252,1);
  border:1px solid rgba(232,232,232,1);
  color:#333333;
  text-align:center;
}
.task_table_item{
  width:50%;
  float:left;
}
.task_table_img{
  width:100%;
}
.task_table_img .task_table_item{
  padding:20px;
  border:1px solid rgba(232,232,232,1);
}
.task_table_item img{
  max-width: 450px;
  max-height:280px;
  margin:auto;
}





