.intro_nav {
  width: 100%;
  height: 60px;
  line-height: 60px;
  color: #333333;
}
.intro_logo {
  float: left;
  height: 100%;
  padding-top: 12px;
  width: 300px;
  margin-left: 30px;
}
.intro_logo p {
  margin-bottom: 0;
}
.intro_logo img {
  width: 30px;
  float: left;
  margin-right: 10px;
}
.intro_nav_section {
  float: right;
  width: 40%;
}
.intro_nav_item {
  width: 20%;
  float: left;
  text-align: center;
  font-size: 14px;
}
.intro_nav_item a {
  color: #333;
  cursor: pointer;
}
.intro_login {
  width: 100%;
  height: 300px;
  position: relative;
}
.intro_login_bg1 {
  background-image: url('/assets/banner1.png');
  background-size: 100% auto;
}
.intro_login_bg2 {
  background-image: url('/assets/banner2.png');
  background-size: 100% auto;
}
.intro_login_bg3 {
  background-image: url('/assets/banner3.png');
  background-size: 100% auto;
  height: 300px;
}
.intro_login_bg3 img {
  width: 600px;
  margin: 50px 0 0 50px;
}
.intro_login_bg4 {
  background-image: url('/assets/banner4.png');
  background-size: 100% auto;
  height: 300px;
}
.intro_text_content {
  float: left;
  padding: 25px 0;
  color: #fff;
  width: 660px !important;
}
.intro_text_content p {
  margin-bottom: 40px;
}
.intro_btn {
  float: left;
  width: 126px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  border-radius: 2px;
  border: 1px solid rgba(255, 255, 255, 1);
  color: #2d9afa;
  margin-right: 10px;
  font-size: 14px;
  cursor: pointer;
  background-color: #fff;
}
.intro_btn_blue {
  background-color: #3296fa;
  color: #fff;
  border: solid 1px #3296fa;
}
.intro_login_content {
  float: right;
  width: 230px;
  height: 240px;
  background: rgba(255, 255, 255, 1);
  border-radius: 4px;
  padding: 20px 30px;
}
.intro_function,
.intro_ugm,
.intro_ugm_plan,
.intro_success {
  width: 100%;
}
.intro_content {
  width: 1200px;
  height: 100%;
  margin: auto;
  padding: 40px 0;
  overflow: hidden;
}
.intro_function_item {
  width: 100%;
  height: 170px;
  background: rgba(250, 250, 250, 1);
  border-radius: 4px;
  padding: 20px;
}
.intro_function_item_title {
  color: #333;
  font-size: 16px;
  margin-bottom: 10px;
}
.intro_function_item_text {
  color: #666;
  line-height: 26px;
}
.intro_ugm,
.intro_success {
  text-align: center;
  color: #333;
  overflow: hidden;
  position: relative;
}
.intro_ugm img {
  width: 70%;
  position: absolute;
  left: 15%;
  z-index: -100;
  margin-top: 20px;
}
.intro_ugm_feature {
  margin-top: 280px;
}
.intro_ugm_feature_item {
  width: 220px;
  height: 202px;
  background: rgba(255, 255, 255, 1);
  border-radius: 2px;
  border: 2px solid rgba(0, 230, 184, 1);
  float: left;
  margin-right: 100px;
  padding: 15px;
}
.intro_ugm_feature_item:hover {
  -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}
.intro_ugm_feature_item_title {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: left;
}
.intro_ugm_feature_item_text {
  font-size: 12px;
  text-align: left;
}
.intro_ugm_plan {
  text-align: center;
  background-color: #f7f7f7;
}
.intro_success_bg {
  width: 90%;
  height: 100%;
  left: 5%;
  z-index: -100;
  position: absolute;
}
.intro_title {
  font-size: 28px;
}
.intro_contact {
  text-align: center;
}
.intro_contact_address {
  float: left;
}
.intro_contact_form {
  width: 40%;
  float: right;
  text-align: left;
}
.intro_footer {
  width: 100%;
  height: 230px;
  background: rgba(32, 37, 42, 1);
  color: #fff;
}
.intro_footer_item {
  float: left;
  width: 260px;
}
.intro_footer_item_title {
  font-size: 18px;
  margin-bottom: 30px;
}
.bottom-logo {
  width: 30px;
  float: left;
  margin-right: 10px;
}
.intro_footer_item p {
  opacity: 0.8;
}
.qrcode {
  position: absolute;
  top: 0;
  right: 0;
  width: 120px;
  text-align: center;
  margin-top: 5px;
  opacity: 1;
}
.qrcode img {
  width: 100%;
}
.ugm_plan_content {
  width: 1000px;
  margin: 30px auto;
}
.ugm_plan_title_section {
  width: 400px;
  float: left;
}
.ugm_plan_plan_section {
  width: 600px;
  float: left;
}
.ugm_plan_header {
  width: 100%;
  text-align: center;
  color: #fff;
  height: 40px;
  line-height: 40px;
  background-color: #3296fa;
}
.ugm_plan_title_item {
  width: 100%;
  height: 84px;
  background: rgba(250, 250, 250, 1);
  border: 1px solid rgba(232, 232, 232, 1);
  padding: 20px 15px 0 15px;
  color: #666666;
}
.ugm_plan_title_item:hover,
.ugm_plan_title_item_active {
  background-color: #fff;
  border-left: solid 4px #3296fa;
  cursor: pointer;
}
.blue_text {
  color: #3296fa;
}
.ugm_plan_plan_item {
  width: 100%;
  height: 336px;
  background-color: #fff;
}
.ugm_plan_plan_item img {
  width: 100%;
  height: 100%;
}
.ugm_success_section {
  width: 400px;
  float: left;
  margin-bottom: 20px;
}
.ugm_success {
  margin-top: 40px;
}
.ugm_success_item {
  width: 350px;
  margin: auto;
  height: 250px;
  border: solid 1px #e8e8e8;
  cursor: pointer;
}
.ugm_success_item img {
  width: 100%;
}
.ugm_success_item:hover {
  -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}
.ugm_success_item_content {
  width: 100%;
  background-color: #fff;
  padding: 10px 10px 0 10px;
  text-align: left;
  height: 63px;
}
.intro_contact {
  background-color: #f7f7f7;
}
.intro_contact img {
  width: 1000px;
  margin: auto;
}
.ant-carousel .slick-slider {
  height: 300px;
}
.intro_contact {
  text-align: center;
}
.intro_contact_section {
  padding: 0 50px;
  margin-top: 50px;
}
.intro_contact_item {
  border: solid 1px #e8e8e8;
  width: 100%;
  background-color: #fff;
  margin: 20px 0;
  position: relative;
}
.intro_contact_item_content {
  width: 33%;
  border-right: solid 5px #f7f7f7;
  text-align: center;
  padding: 40px 0;
  float: left;
  height: 100%;
}
.intro_contact_item_content p {
  font-weight: bold;
  font-size: 18px;
  color: #333333;
}
.blue-text {
  color: #1890ff !important;
  font-weight: 100 !important;
  font-size: 26px !important;
  margin-top: 30px !important;
}
.green-text {
  color: #00c466 !important;
  font-weight: 100 !important;
  font-size: 26px !important;
  margin-top: 30px !important;
}
.item_icon {
  width: 80px !important;
  margin-top: 30px !important;
}
.item_arrow {
  width: 50px !important;
  top: 100px;
  position: absolute;
}

// ilm
.intro_login_bg1_ilm {
  background-image: url('/assets/ilm_banner1.png');
}
.intro_login_bg2_ilm {
  background-image: url('/assets/ilm_banner2.png');
}
.intro_login_bg3_ilm {
  background-image: url('/assets/ilm_banner3.png');
}
.intro_function_item_ilm {
  height: 170px;
}
.ugm_plan_title_item_ilm {
  width: 100%;
  height: 84px;
  background: rgba(250, 250, 250, 1);
  border: 1px solid rgba(232, 232, 232, 1);
  padding: 20px 15px 0 15px;
  color: #666666;
}
.ugm_plan_title_item_ilm:hover,
.ugm_plan_title_item_ilm_active {
  background-color: #fff;
  border-left: solid 4px #68c269;
  cursor: pointer;
}
.intro_login_bg1_isq {
  background-image: url('/assets/isq_banner1.png');
}
.intro_login_bg2_isq {
  background-image: url('/assets/isq_banner2.png');
}
.intro_login_bg3_isq {
  background-image: url('/assets/isq_banner3.png');
}

// icc
.intro_login_bg1_icc {
  background-image: url('/assets/icc_banner1.png');
}
.intro_login_bg2_icc {
  background-image: url('/assets/icc_banner2.png');
}
.intro_login_bg3_icc {
  background-image: url('/assets/icc_banner3.png');
}
.intro_login_bg4_icc {
  background-image: url('/assets/icc_banner4.png');
}
// 石景山登录
.login_bg {
  position: absolute;
  background-image: url('/assets/mtg-bg.jpg');
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.login_content {
  width: 90%;
  padding: 20px;
  margin-top: 150px;
  margin-left: auto;
  margin-right: auto;
}
.login_text {
  float: left;
  color: #fff;
  font-size: 46px;
  font-weight: 200;
  letter-spacing: 5px;
  margin-left: 80px;
  text-shadow: 2px 2px 4px #000000;
  margin-top: -90px;
}
.login_container {
  float: right;
  padding: 50px;
  background-color: #fff;
  border: solid 1px #ccc;
  border-radius: 5px;
  margin-top: -50px;
}
.login_container p {
  font-size: 22px;
}
