@import 'stylesheets/show.scss';
@import 'stylesheets/cases.scss';
@import 'stylesheets/index.scss';
@import 'stylesheets/login.scss';
@import 'stylesheets/download.scss';
@import 'stylesheets/grids.scss';
@import 'stylesheets/statistics.scss';
@import 'stylesheets/data.scss';
@import 'stylesheets/data_statistics.scss';

.react-resizable {
  position: relative;
}

.react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
}

.logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 28px 16px 0;
  float: left;
}

.header_logo {
  float: left;
}
.header_logo_container {
  background-image: url('/assets/UGM-logo.png');
  width: 30px;
  height: 36px;
  background-size: 100% 100%;
  margin: 13px 15px 0 0;
  float: left;
}
.header_logo span {
  color: #fff;
  line-height: 20px;
  width: 170px;
  display: block;
  float: left;
  margin-top: 12px;
}
.ant-layout {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
  background: #f0f2f5;
}
.ant-layout,
.ant-layout * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.ant-layout.ant-layout-has-sider {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  overflow-x: hidden;
}
.ant-layout-header,
.ant-layout-footer {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.ant-layout-header {
  background: #001529;
  padding: 0 50px;
  height: 64px;
  line-height: 64px;
}
.ant-layout-footer {
  background: #f0f2f5;
  padding: 24px 50px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
}
.ant-layout-content {
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
}
.ant-layout-sider {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  position: relative;
  background: #001529;
  /* fix firefox can't set width smaller than content on flex item */
  min-width: 0;
}
.ant-layout-sider-children {
  height: 100%;
  padding-top: 0.1px;
  margin-top: -0.1px;
}
.ant-layout-sider-has-trigger {
  padding-bottom: 48px;
}
.ant-layout-sider-right {
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
}
.ant-layout-sider-trigger {
  position: fixed;
  text-align: center;
  bottom: 0;
  cursor: pointer;
  height: 48px;
  line-height: 48px;
  color: #fff;
  background: #002140;
  z-index: 1;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.ant-layout-sider-zero-width > * {
  overflow: hidden;
}
.ant-layout-sider-zero-width-trigger {
  position: absolute;
  top: 64px;
  right: -36px;
  text-align: center;
  width: 36px;
  height: 42px;
  line-height: 42px;
  background: #001529;
  color: #fff;
  font-size: 18px;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}
.ant-layout-sider-zero-width-trigger:hover {
  background: #192c3e;
}
.ant-layout-sider-light {
  background: #fff;
}
.ant-layout-sider-light .ant-layout-sider-trigger {
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
}
.ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
}

// header
.ant-layout-header {
  background-image: url('/assets/banner@3x.png');
}
.header_nav {
  float: right;
  color: #fff;
}
.header_nav a {
  color: #fff;
}

.header_nav_item {
  height: 30px;
  line-height: 30px;
  margin-top: 17px;
  float: left;
  text-align: center;
  border-right: solid 1px rgba(255, 255, 255, 0.2);
  cursor: pointer;
  padding: 0 10px;
}
.header_nav_item_active,
.header_nav_item:hover {
  color: #00ece5;
}
.header_nav_item_line {
  width: 34px;
  height: 2px;
  background: #00ece5;
  margin: auto;
}
.header_user {
  float: right;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
}
.opra_container {
  width: 100%;
  height: 56px;
  line-height: 56px;
  background: rgba(58, 160, 255, 0.1954);
  border: 5px;
  margin-bottom: 10px;
  color: #2b486d;
  padding: 0 20px;
}
.opra_container .btn-space button {
  margin-right: 0.6rem;
}
.ant-table-thead th span {
  color: #0061a6;
}
.form_btn {
  width: 100%;
  text-align: center;
}

.map-list {
  height: 480px;
}
.image_container {
  float: left;
  margin: 0 10px 10px 0;
}
.image_dele_btn {
  position: absolute !important;
  margin-left: -50px;
  margin-top: 150px;
}

.spin {
  width: 100%;
  height: 80px;
  border-radius: 4px;
  text-align: center;
  margin-top: 30px;
}
.success_info {
  position: 'relative';
  width: 100%;
  border: 1px solid #b7eb8f;
  background-color: #f6ffed;
  padding: 20px;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 10px;
  border-radius: 10px;
}
.success_info > .anticon {
  position: absolute;
  right: 0.4rem;
  top: 0.4rem;
  cursor: pointer;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  will-change: transform;
}
.success_info > .anticon:hover {
  transform: scale(1.4);
}
