.down_group, .down_search{
	float:right;
}
.down_icon{
	width:40px;
	float:left;
	margin-right:20px;
}
.down_title{
	font-size:16px;
	font-weight:bold;
	margin-bottom:12px;
}
.down_table{
	margin-top:20px;
}
