.statistics_title{
  height: 45px;
  line-height: 45px;
  border-bottom: solid 1px #E8E8E8;
  font-size: 16px;
  padding-bottom: 20px;
}
.statistics_time{
  float: right;
}
.statistics_content{
  padding:20px 0;
  overflow: hidden;
}
.statistics_sta{
  width: 250px;
  text-align: center;
  float: left;
  border-right: solid 1px #e8e8e8;
  line-height: 30px;
}
.case_hidden, .wbj_hidden, .jd_hidden{
  display: none
}
.statistics_table{
  float:right;
  width: 600px;
  margin:50px 20px 0 0;
}
.statistics_table th{
  background:#fff !important;
  border-bottom: none !important;
  padding:10px 0 !important;
}
.statistics_table th span{
  color:#4A4A4A !important;
}
.statistics_table td{
  border-bottom: none !important;
  padding:10px 0 !important;
}
.statistics_paiming{
  width:20px;
  height:20px;
  background:rgba(24,144,255,1);
  border-radius: 10px;
  color:#fff;
  display: inline-block;
  text-align: center;
}